import React, { useState, useReducer } from 'react'
import { graphql } from 'gatsby'
import IngredientTile from '../../components/shared/IngredientTile'
import { CartContext } from '../../components/shared/CartProvider'
import { ingredientsBySection } from '../../common/sections'

import IndexLayout from '../../layouts'

import Delivery from '../../images/svgs/delivery.svg'

export const sellableRecipes = ["tahini-quinoa", "curry-lentil-soup", "red-lentil-hummus"]

const unique = (array) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item.id)) {
      map.set(item.id, true);    // set any value to Map
      result.push(item);
    }
  }

  return result
}

const getAllIngredients = (recipes, filterRecipe) => {
  let filterableRecipes = recipes
  if (filterRecipe) {
    filterableRecipes = [filterRecipe]
  }

  const allShoppableIngredients = filterableRecipes.map((recipe) => {
    return recipe.ingredients.map((ingredient) => {
      return ingredient.ingredient
    })
  }).flat().filter((obj) => {
    return obj && obj.priceCents && obj.sku
  })

  return unique(allShoppableIngredients)
}

const orderedRecipes = (recipes) => {
  return sellableRecipes.map((recipeName) => {
    return recipes.find((recipe) => {
      return recipe.slug == recipeName
    })
  })
}

const PlantBasedStarter = ({ data }) => {
  const [filterRecipe, setFilterRecipe] = useState(null)
  const [firstRender, setFirstRender] = useState(true)
  const [_ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const allIngredients = getAllIngredients(data.allContentfulRecipe.nodes, filterRecipe)
  setTimeout(() => {
    if (firstRender) {
      console.log('First Render')
      setFirstRender(false)
      forceUpdate()
    }
  }, 3000)

  const addRecipeToCart = (cart, recipe) => {
    recipe.ingredients.forEach((ingredient) => {
      const sellableIngredient = ingredient.ingredient

      if (!sellableIngredient) {
        return
      }

      if (sellableIngredient.priceCents && sellableIngredient.sku && !cart.contains(sellableIngredient)) {
        console.log(`SKU: ${sellableIngredient.sku}`)
        cart.add(sellableIngredient.sku)
      }
    })
  }

  const allRecipeInCart = (cart, recipe) => {
    const missingIngredientIndex = recipe.ingredients.findIndex((ingredient) => {
      return ingredient.ingredient && ingredient.ingredient.priceCents && ingredient.ingredient.sku && !cart.contains(ingredient.ingredient)
    })

    return missingIngredientIndex == -1
  }

  const setFilter = (recipe) => {
    if (recipe == filterRecipe) {
      setFilterRecipe(null)
    } else {
      setFilterRecipe(recipe)
    }
  }

  const sectionedIngredients = ingredientsBySection(allIngredients)
  const displayedRecipes = orderedRecipes(data.allContentfulRecipe.nodes)

  return (
    <IndexLayout>
      <div className="container">
        <div className="row py-2 mb-5">
          <div className="col-12">
            <h2 className="font-weight-bold display-1">Let us do the shopping for you</h2>
            <h4 className="font-weight-light">
              We're opening a plant-based grocery store near you, in the meantime we wanted to give you a taste of what's to come.
              Try out some of our recipes and we'll deliver the ingredients next day for <b>free</b>.
            </h4>
          </div>
        </div>
      </div>
      <section id="recipes">
        <div className="container">
          <div className="row">
            {displayedRecipes.map((recipe, index) => {
              return (
                <div className="col-12 col-md-4 px-2 mb-3" key={`recipe-${index}-${recipe.slug}`}>
                  <div className="border rounded p-3 h-100 position-relative mb-5">
                    <a href={`/recipes/${recipe.slug}`}>
                      <img className="img-fluid mh-100" src={recipe.mainImage?.file?.url} alt="The recipe" />
                    </a>
                    <h4 className="text-left"><a href={`/recipes/${recipe.slug}`} target="_blank">{recipe.name}</a></h4>
                    <div className="small" dangerouslySetInnerHTML={{ __html: recipe.homepageDescription?.childMarkdownRemark?.html }}></div>
                    <CartContext.Consumer>
                      {(cart) => {
                        return (
                          <button
                            className="btn-lg btn-primary position-absolute full-width-bottom rounded-bottom add-to-cart"
                            onClick={() => {
                              addRecipeToCart(cart, recipe)
                            }}
                            disabled={allRecipeInCart(cart, recipe)}
                          >
                            Add ingredients to cart
                          </button>
                        )
                      }}
                    </CartContext.Consumer>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section id="shoppageIngredients" className="container pt-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h3 className="font-weight-bold">Shop ingredients</h3>
            <p className="font-weight-light small mb-0">Everything you need to make all three of these recipes, select what you need below.</p>
          </div>
        </div>
        <div className="row border-bottom pb-3">
          <div className="col-12">
            <h5>Filter by recipe</h5>
          </div>
          {data.allContentfulRecipe.nodes.map((recipe, index) => {
            const selected = filterRecipe === recipe

            return (
              <div className={`cursor-pointer col-12 col-sm-4 col-xl-3 d-flex rounded ${selected ? 'bg-success' : ''}`} key={`filter-recipe-${index}`} onClick={() => {
                setFilter(recipe)
              }}>
                <img className="img-fluid mh-100 h-90p" src={recipe.mainImage?.file?.url} alt="The recipe" />
                <div className={`my-auto ${selected ? 'text-white' : ''}`}>{recipe.name}</div>
              </div>
            )
          })}
        </div>

        {sectionedIngredients.map((section, sectionIndex) => {
          return (
            <div key={`secion-${sectionIndex}`} className="row">
              <div className="col-12">
                <h4 className="font-weight-bold">{section.sectionName}</h4>
              </div>
              {section.ingredients.map((ingredient) => {
                return (
                  <IngredientTile ingredient={ingredient} key={`ingredient-tile-${ingredient.sku}`} />
                )
              })}
            </div>
          )
        })}

        <div className="row">
          <div className="col-12 col-md-10 pt-5">
            <span>Email or text us any time with special instructions or requests for delivery. <b>hey@elmfoods.com</b> or <b>424-242-0051</b></span><br />
          </div>
          <div className="col-12 col-md-2 text-center d-flex pt-5">
            <Delivery className="mx-auto my-auto" />
          </div>
        </div>
      </section>
    </IndexLayout >
  )
}

export const query = graphql`
  query PlantBasedStarterQuery {
    site {
      siteMetadata {
        description
      }
    }
    allContentfulRecipe(filter: {slug: {in: ["curry-lentil-soup", "red-lentil-hummus", "tahini-quinoa"]}}) {
      nodes {
        name
        slug
        homepageDescription {
          childMarkdownRemark {
            html
          }
        }
        mainImage {
          file {
            url
          }
        }
        ingredients {
          ingredient {
            name
            sku
            priceCents
            id
            section
            quantityDescription
            picture {
              file {
                url
              }
            }
          }
        }
        homepageDescription {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default PlantBasedStarter
